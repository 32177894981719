import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CasesService } from 'app/shared/cases/services/cases.service';
import { ActivitiesService } from 'app/shared/cases';
import { forkJoin, Subscription } from 'rxjs';
import { LogType } from 'app/shared/logs/types';
import { ModalService } from '../modal/modal.service';
import { CoreLibShareModalComponent } from '../share-modal/share-modal.component';
import { UtilService } from 'app/shared/util.service';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { FilesService } from 'app/shared/services/files.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CoreLibEmptyModalComponent } from '../empty-modal/empty-modal.component';
import { Store } from '@ngxs/store';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Activity } from 'app/new-fs/desk/models/Activity.model';
import * as FileSaver from 'file-saver';
import { BreakpointService } from 'app/core-lib/services/breakpoints/breakpoint.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DateTime } from 'luxon';
import { Case } from 'app/shared/model/guest-answer.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UsersState } from 'app/states/users/users.state';
import { FidelityDeskState } from 'app/states/fidelity-desk/fidelity-desk.state';

const LOG_ICONS = {
  caseChangeState: 'bell',
  caseChangeAssigned: 'user-edit',
  editCase: 'pencil',
  createCase: 'plus',
  default: 'pencil',
};
const FOLLOWUP_SURVEY = '1_1';
const ONSITE_SURVEY = '2_1';
const ONLINE_REVIEW = '3_2';
const PRESTAY_PRECHECKIN = '4_3';
const PRESTAY_ORDER = '4_4';
const ACTIVITY_COMMENT_TYPE = { id: 1 };

enum StatesTypes {
  PENDING = 1,
  PROCESS = 2,
  FINISHED = 3,
  DISCARDED = 4,
}

@Component({
  selector: 'mh-core-lib-case-detail-modal',
  templateUrl: './case-detail-modal.component.html',
  styleUrls: ['./case-detail-modal.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('500ms', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('500ms', style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
})
export class CoreLibCaseDetailModalComponent extends CoreLibModalComponent implements OnInit, OnDestroy {
  base;
  languageId = this.store.selectSnapshot(DefaultLanguageState.languageId);
  stateTypesEnum: typeof StatesTypes = StatesTypes;
  editing = false;
  descriptionForm;
  commentForm: FormGroup;
  mentionState;
  caseData;
  uploadingFiles = false;
  completedActions = false;
  loadingImage = false;
  showCaseForm = false;
  showCommentForm = false;
  selectedFile: File;
  activities = [];
  comments = [];
  allResources = [];
  downloadedFiles = [];
  waitingFileDownload = [];
  newActivities: Activity[] = [];
  loadingActivities = false;
  acceptedImageTypes: string[] = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml', 'image/webp'];
  acceptedDocumentTypes: string[] = ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
  acceptedExtensionImageFiles: string[] = ['jpeg', 'png', 'jpg', 'gif', 'svg', 'webp'];
  videoExtensionsToDownload: string[] = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'mpeg', '3gp'];
  imageLink;
  fileInput: HTMLInputElement;
  filesUploaded: File[] = [];
  dueDateText: string;
  dueTimeText: string;
  defaultProfilePhoto = 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png';
  showDiscardCase = false;
  sendingComment = false;

  PRODUCT_MODULE_NAMES = {
    [FOLLOWUP_SURVEY]: 'Follow Up',
    [ONSITE_SURVEY]: 'Onsite',
    [ONLINE_REVIEW]: 'Online',
    [PRESTAY_PRECHECKIN]: 'Prestay Web Check in',
    [PRESTAY_ORDER]: 'Prestay Servicios',
  };

  subscriptions = new Subscription();
  waiting = true;
  loading = false;
  waitingRecords = true;
  isExpired = false;
  remainingPercentage = 0;
  optionStates = [
    { id: 1, value: 'pending', selected: false },
    { id: 2, value: 'process', selected: false },
    { id: 3, value: 'finished', selected: false },
    { id: 4, value: 'discarded', selected: false },
  ];
  colorVariants = {
    blue: 'tw-bg-sky-500',
    yellow: 'tw-bg-yellow-500',
    red: 'tw-bg-red-500',
  };

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private casesService: CasesService,
    private activitiesService: ActivitiesService,
    private modalService: ModalService,
    private utilService: UtilService,
    private alertService: AlertService,
    private filesService: FilesService,
    private sanitizer: DomSanitizer,
    private store: Store,
    private breakpointService: BreakpointService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.setCase();
    this.setDescriptionForm();
    this.setCommentForm();
    this.fetchActivitiesComments();
    this.getFormatDueDate();

    this.subscriptions.add(
      this.updatedCase$.subscribe(({ casePublic, subscribers }) => {
        this.markStagnantStatus(casePublic);
        this.fetchActivitiesComments();
        this.data = {
          ...this.data,
          subscribers,
        };
        this.setCase({ ...casePublic, product_item: this.data.case.product_item });
      }),
    );

    this.subscriptions.add(
      this.isDataForModalReady$.subscribe((response) => {
        this.waiting = false;
        this.data = {
          ...this.data,
          ...response,
        };
      }),
    );

    this.base = this.store.selectSnapshot(FidelityDeskState.base);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getFormatDueDate() {
    if (this.caseData?.due_date) {
      const dueDate = this.caseData.due_date;
      this.dueDateText = DateTime.fromISO(dueDate.split(' ')[0]).toFormat('dd/LL/yyyy');
      this.dueTimeText = dueDate.split(' ')[1];
    }
  }

  async checkAssigned() {
    try {
      const res = await this.casesService.seenByAssignedUser(this.caseData.id).toPromise();
      if (res && this.caseData?.assigned?.id === this.currentUser.id) {
        const msg = this.translateService.instant('cases.detail_modal.reviewed_now', {
          firstName: this.caseData?.assigned?.first_name,
          lastName: this.caseData?.assigned?.last_name,
        });
        this.caseData = { ...this.caseData, viewed: true, viewed_text: msg };
      }
    } catch (error) {
      console.error(error);
    }
  }

  markStagnantStatus(caseValueEntry) {
    this.caseData.stagnant = caseValueEntry.stagnant;
  }

  fetchActivitiesComments() {
    this.loadingActivities = true;
    const languageId = this.languageId || this.utilService.getCurrentLanguageId();
    this.activitiesService.getLogsForCase(this.caseData.id, languageId).subscribe(
      (activities: Activity[]) => {
        if (activities?.length > 0) {
          this.newActivities = activities.map((activity) => {
            return {
              ...activity,
              activity_text: activity.activity_text.replace(/\n/g, '<br>'),
              log_content: {
                ...activity.log_content,
                text: activity?.log_content?.text?.replace(/\n/g, '<br>'),
              },
            };
          });
        }
        this.loadingActivities = false;
      },
      () => (this.loadingActivities = false),
    );
  }

  setActivities(logs) {
    const NON_SHOWN_CHANGES = ['id', 'case_id', 'typeid', 'typename', 'item_id', 'product_id'];

    return (logs || [])
      .filter((log) => log.sub_resource_name !== 'action' && log.modifications && log.modifications.length > 0)
      .map((log) => {
        const operation = log.request_method === 'POST' ? 'ADD' : 'EDIT'; //podria corregirlo el back
        const productType = (log.modifications.find((l: any) => l.name === 'product_id') || {}).actual;
        const changes = (log.modifications || [])
          .filter((c: any) => !NON_SHOWN_CHANGES.includes(c.name))
          .map((c: any) => ({ ...c, operation }))
          .map((c: any) => (c.name !== 'createdAt' ? c : { ...c, actual: `${c.actual} UTC` }))
          .map((c: any) =>
            c.name !== 'product_item_type'
              ? c
              : {
                  ...c,
                  actual: {
                    ...c.actual,
                    name: this.PRODUCT_MODULE_NAMES[`${productType}_${c.actual.id}`],
                  },
                },
          );

        return {
          ...log,
          changes,
          resourceName: log.resource_name,
          type: LogType[log.request_method as keyof LogType],
          icon: 'fa-' + (LOG_ICONS[log.request_method_name] || LOG_ICONS.default),
          operation,
        };
      })
      .sort((l1, l2) => (l1.timestamp > l2.timestamp ? -1 : 1));
  }

  setCase(updatedCase: Case = null) {
    const caseData = updatedCase || this.data.case;
    this.caseData = { ...caseData };
    if (!updatedCase) this.getAttachments();
    this.getPercentages();
    this.checkAssigned();
  }

  setDescriptionForm() {
    this.descriptionForm = this.fb.group({
      title: this.caseData.title,
      description: this.caseData.description,
    });
  }

  setCommentForm() {
    this.commentForm = this.fb.group({
      comment: [''],
    });
  }

  handleEditing() {
    this.setDescriptionForm(); // reset description value
    this.editing = true;
  }

  async handleAcceptEditDescription() {
    this.replaceMentionsBeforeSubmit(this.descriptionForm, 'description');
    this.loading = true;
    const obs$ = [];
    if (this.caseData.title !== this.descriptionForm.value.title) {
      obs$.push(this.casesService.updateTitle(this.caseData.id, this.descriptionForm.value.title));
    }

    if (this.caseData.description !== this.descriptionForm.value.description) {
      obs$.push(this.casesService.updateDescription(this.caseData.id, this.descriptionForm.value.description));
    }

    this.caseData.title = this.descriptionForm.value.title;
    this.caseData.description = this.descriptionForm.value.description;

    try {
      const res = await forkJoin(obs$).toPromise();
      if (res) {
        this.emitCaseFn(this.caseData);
        this.utilService.ga('cases', 'desk-click-edit-case');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.editing = false;
      this.loading = false;
    }
  }

  handleCancelEditDescription() {
    this.editing = false;
  }

  handleUpdatedCase($event) {
    this.emitCaseFn($event);
  }

  handleRecordCaseDetailAction() {
    this.waitingRecords = true;
    this.emitCaseFn(this.caseData);
  }

  async handleStateChange(option) {
    if (this.caseData.state.id === option.id) return;
    this.updateCaseState(option);
  }

  async updateCaseState(option) {
    this.loading = true;
    try {
      const res = await this.casesService.updateState(this.caseData.id, option.id).toPromise();
      if (res) {
        this.caseData.state = { ...this.caseData.state, id: option.id };
        this.emitCaseFn(this.caseData);
        this.showNotification('cases.commons.success_state');
        this.handleUpdatedCase(this.caseData);
        this.utilService.ga('cases', 'desk-click-change-stage');
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.showNotification('cases.commons.error_state', 'error');
      this.loading = false;
    }
  }

  showNotification(messageKey: string, type = 'success') {
    this.alertService.handleAlert(messageKey, 5000, 'bottom', 'end', `snackbar-panel-${type}-user-config`);
  }

  handleShareCase() {
    const classes = ['overlay-panel', 'fit-height-content'];
    const backdropClasses = ['overlay-backdrop-inherit'];
    const config = { data: { case: this.caseData } };
    this.modalService.open(CoreLibShareModalComponent, config, classes, backdropClasses);
    this.utilService.ga('cases', 'desk-click-share-modal');
  }

  handleMentionState(mention) {
    this.mentionState = mention;
  }

  async submitComment() {
    this.sendingComment = true;
    this.replaceMentionsBeforeSubmit(this.commentForm, 'comment');
    const payload = {
      author_id: this.currentUser.id,
      case_id: this.caseData.id,
      text: this.commentForm.get('comment')?.value || '',
      type: ACTIVITY_COMMENT_TYPE,
      attachments: this.filesUploaded || [],
    };

    try {
      const res = await this.activitiesService.createComment(payload).toPromise();
      if (res) {
        this.commentForm.get('comment').reset();
        this.showNotification('cases.detail_modal.added_comment');
        this.fetchActivitiesComments();
        this.completedActions = true;
        this.filesUploaded = [];
        if (this.isMobileOrTablet) this.showCommentForm = false;
        this.utilService.ga('cases', 'desk-click-comment-case');
        this.sendingComment = false;
      }
    } catch (error) {
      console.error(error);
      this.sendingComment = false;
    }
  }

  replaceMentionsBeforeSubmit(form: FormGroup, controlKey: string) {
    const control = form.get(controlKey);
    const value = control.value;
    const matches = value?.match(/@\[(.*?)\]/g) || [];
    matches?.forEach((match) => {
      const mention = this.mentionState?.find((findedMention) => findedMention.key === match);
      if (mention) {
        const text = control.value.replace(match, mention.value);
        control.setValue(text);
      }
    });
  }

  getAttachments() {
    if (this.caseData.attachments.length === 0) return;
    this.caseData.attachments.forEach((file: File) => {
      const fileExtension = file.file_key.split('.')[1].toLowerCase();
      if (this.acceptedExtensionImageFiles.includes(fileExtension)) {
        this.handleDowloadEvent(file);
      } else {
        file = { ...file, type: `application/${fileExtension}` };
        this.downloadedFiles.push(file);
      }
    });
  }

  async handleDowloadEvent(file: File, source = 'description', needToDownload = false) {
    const fileExtension = file.file_key.split('.')[1].toLowerCase();
    const type = this.acceptedDocumentTypes.includes(fileExtension)
      ? `application/${fileExtension}`
      : `image/${fileExtension}`;

    try {
      const res = await this.filesService.downloadFileToShow(this.customerId, file.file_key).toPromise();
      if (res) {
        this.downloadFile(res, type, source, file, needToDownload);
      }
    } catch (error) {
      console.error(error);
    }
  }

  downloadFile(res, type: string, source: string, initialFileData?: File, needToDownload = false) {
    const file = new Blob([res], { type });
    const blob = window.URL.createObjectURL(file);
    const sanitizeLink = this.sanitizer.bypassSecurityTrustResourceUrl(blob);
    const extensionType = type.split('/')[1];

    if (
      this.acceptedDocumentTypes.includes(extensionType) ||
      this.videoExtensionsToDownload.includes(extensionType) ||
      needToDownload
    ) {
      FileSaver.saveAs(file, this.selectedFile?.file_name || initialFileData?.file_name);
      return;
    }

    if (source === 'description') {
      const fileData = {
        link: sanitizeLink,
        type,
        initialFileData,
      };
      this.downloadedFiles.push(fileData);
    } else {
      this.expandImage(sanitizeLink, initialFileData);
    }
  }

  getPercentages() {
    if (this.caseData?.time_management?.remaining_time < 0) {
      this.isExpired = true;
      return;
    }
    const total = this.caseData?.time_management?.total_time;
    const remaining = this.caseData?.time_management?.remaining_time;
    this.remainingPercentage = Math.round((remaining * 100) / total);
  }

  expandImage(file: SafeResourceUrl, fileData?: File) {
    this.selectedFile = fileData;
    const config = {
      data: {
        file,
        type: 'image',
      },
    };
    const backdropClasses = ['overlay-backdrop-inherit'];
    const modalRef = this.modalService.open(CoreLibEmptyModalComponent, config, null, backdropClasses);
    modalRef.afterClosed().subscribe(async (response) => {
      if (response && response === 'download') {
        this.loadingImage = true;
        await this.handleDowloadEvent(fileData, 'description', true);
        this.loadingImage = false;
      }
    });
  }

  handleFilesUploaded(files: File[]) {
    this.filesUploaded = [...this.filesUploaded, ...files];
    this.utilService.ga('cases', 'desk-click-attach-documents');
  }

  async showOrDownloadFile(file: File, source: string) {
    this.selectedFile = file;
    try {
      this.loadingImage = true;
      await this.handleDowloadEvent(file, source);
      this.loadingImage = false;
    } catch (error) {
      console.error(error);
    }
  }

  async handleDiscardCase($event) {
    const { reason, reasonComment } = $event;
    const body = {
      case_id: this.caseData.id,
      reason_id: reason,
      reason_comment: reasonComment ?? null,
    };
    try {
      const resUpdate = await this.casesService.discardCase(body).toPromise();
      if (resUpdate) {
        this.caseData.state = { ...this.caseData.state, id: this.stateTypesEnum.DISCARDED };
        this.emitCaseFn(this.caseData);
        this.showNotification('cases.commons.success_state');
        this.handleUpdatedCase(this.caseData);
        this.utilService.ga('cases', 'desk-click-change-stage');
      }
    } catch (error) {
      console.error(error);
      this.showNotification('cases.commons.error_state', 'error');
    }
  }

  get title() {
    return this.caseData.title;
  }

  get created() {
    return this.caseData.created_at;
  }

  get createdBy() {
    return `${this.caseData.author.first_name} ${this.caseData.author.last_name}`;
  }

  get publicId() {
    return this.caseData.public_id;
  }

  get description() {
    return this.caseData.description;
  }

  get isReview() {
    return this.caseData.product_item?.type.name === 'review';
  }

  get isNps() {
    return this.caseData.product_item?.type.name === 'survey-answer';
  }

  get isPrestayPrecheckin() {
    return this.caseData.product_item?.type.name === 'pre-checkin';
  }

  get isPrestayOrder() {
    return this.caseData.product_item?.type.name === 'order';
  }

  get customerId() {
    return this.data.currentCustomerId;
  }

  get users() {
    return this.store.selectSnapshot(UsersState.currentUsers);
  }

  get types() {
    return this.data.types;
  }

  get displayFnTypes() {
    return this.data.translatesPipe.types;
  }

  get priorities() {
    return this.data.priorities;
  }

  get displayFnPriorities() {
    return this.data.translatesPipe.priorities;
  }

  get areas() {
    return this.data.areas;
  }

  get displayChipsFn() {
    return this.data.translatesPipe.chips;
  }

  get states() {
    return this.data.states;
  }

  get subscribers() {
    return this.data.subscribers;
  }

  get currentUser() {
    return this.data.currentUser;
  }

  get language() {
    return this.data.currentLanguage;
  }

  get emitCaseFn() {
    return this.data.emitCaseFn;
  }

  get updatedCase$() {
    return this.data.updatedCaseObservable;
  }

  get isDataForModalReady$() {
    return this.data.dataReadyObservable;
  }

  get isMobileOrTablet() {
    return this.breakpointService.isMinorThanLg();
  }

  get isHistoryPage() {
    const HISTORY_PAGE = 'history';
    return this.router.url.includes(HISTORY_PAGE);
  }

  get isAssigned() {
    return this.caseData.assigned.id === this.currentUser.id;
  }
}

export interface File {
  file_key: string;
  file_name: string;
  type?: string;
}
