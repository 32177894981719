import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Case, Base } from '../types';
import { Case as newFsCase } from 'app/new-fs/desk/models/Case.model';
import { Params } from '@angular/router';
import { OnsiteGuests } from '../types/OnsiteGuests.model';
import { Team } from 'app/new-fs/gestor-usuarios/teams/model/teams.model';
import Language from 'app/features/surveys/features/mailing/shared/models/mail-setup';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/shared/model';
import { AreaEntity, CaseType } from 'app/new-fs/desk/models/Case.model';
import { UtilService } from 'app/shared/util.service';
import { DetectedCase, DetectedCasesByProduct } from 'src/app/core-lib/dialogs/create-case-modal/model/detected-case.model';
import { ScheduleData } from 'src/app/shared/model/schedule.model';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CasesService {
  base: Base;
  usersToSubscribe = [];
  caseDeleted = new Subject();
  caseDetectedDiscarded$ = new Subject();

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getByUserId = (
    customerId: string | number,
    userId: string | number,
    languageId: number | Language,
    params?: Params,
  ): Observable<Object> => {
    const url = `${environment.casesPath}customers/${customerId}/users/${userId}/cases`;
    if (params) {
      params = params.set('language_id', languageId.toString());
    } else {
      params = new HttpParams().set('language_id', languageId.toString());
    }

    return this.http.get(url, { params: params });
  };

  getActive = (customerId: string | number, languageId: number | Language, params?: Params, getHistoric = false): Observable<Object> => {
    const url = `${environment.casesPath}customers/${customerId}/cases/opened`;
    if (params) {
      params = params.set('language_id', languageId);
    } else {
      params = new HttpParams().set('language_id', languageId.toString());
    }

    if (getHistoric) {
      const historicFetch = this.getHistoricByState(customerId, languageId, {
        startDate: this.getDateMinusDays(5),
        endDate: this.getTodayDate(),
      });
      return forkJoin({ historic: historicFetch, active: this.http.get(url, { params: params }) }).pipe(
        map((result: { active; historic: { info: Case[] } }) => {
          return [...(result?.active ?? []), ...(result?.historic?.info ?? [])];
        }),
      );
    }
    return this.http.get(url, { params: params });
  };

  getHistoric = (
    customerId: string | number,
    language: number | Language,
    paramsWithDates?: any,
  ): Observable<Object> => {
    const { startDate, endDate } = paramsWithDates;
    const url = `${environment.casesPath}customers/${customerId}/cases/closed`;
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('language_id', language.toString());

    return this.http.get(url, { params });
  };

  getHistoricByState = (
    customerId: string | number,
    language: number | Language,
    paramsWithDates?: any,
  ): Observable<Object> => {
    const { startDate, endDate } = paramsWithDates;
    const url = `${environment.casesPath}customers/${customerId}/cases/closed/last-state-change`;
    // customers/${customerId}/cases/closed/last-state-change?language_id=1&start_date=2024-01-01&end_date=2024-01-30&page=0&page_size=10&paged=true
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('language_id', language.toString())
      .set('paged', false);

    return this.http.get(url, { params });
  };

  getByPublicId(publicId: string, customerId: string | number): Observable<any> {
    const url = `${environment.casesPath}customers/${customerId}/public/${publicId}`;

    return this.http.get(url);
  }

  getBase(): Observable<any> {
    const url = `${environment.casesPath}base`;
    return this.http.get(url);
  }

  getSubscribers(caseId: number): Observable<any> {
    const url = `${environment.casesPath}${caseId}/subscriptions`;

    return this.http.get(url);
  }

  subscribe(caseId: number): Observable<any> {
    const url = `${environment.casesPath}${caseId}/subscribe`;

    return this.http.patch(url, {});
  }

  unsubscribe(caseId: number): Observable<any> {
    const url = `${environment.casesPath}${caseId}/subscribe`;
    const params = new HttpParams().set('action', 'unsubscribe');
    return this.http.patch(url, {}, { params });
  }

  updateAssigned(caseId: number, userId: number) {
    const url = `${environment.casesPath}${caseId}/users/${userId}/assigned`;

    return this.http.patch(url, {});
  }

  updateState(caseId: number, stateId: number) {
    const url = `${environment.casesPath}${caseId}/states/${stateId}`;

    return this.http.patch(url, {});
  }

  discardCase(body) {
    const url = `${environment.casesPath}discard`;
    return this.http.post(url, body);
  }

  updatePriority(caseId: number, priorityId: number) {
    const url = `${environment.casesPath}${caseId}/priorities/${priorityId}`;

    return this.http.patch(url, {});
  }

  save(_case: any, customerId?: string) {
    if (_case.id) {
      return this.update(_case);
    }

    return this.create(_case, customerId);
  }

  create(_case: Case, customerId?: string) {
    const url = `${environment.casesPath}customers/${customerId}/cases`;

    return this.http.post(url, _case, {});
  }

  update(_case: Case) {
    const url = `${environment.casesPath}${_case.id}`;

    return this.http.put(url, _case, {});
  }

  delete(_case: newFsCase) {
    const url = `${environment.casesPath}`;
    const params = new HttpParams().set('case_ids', _case.id);
    return this.http.delete(url, { params });
  }

  updateSubscribers(_case: Case, subscribers: number[]) {
    const url = `${environment.casesPath}${_case.id}/subscribe`;
    const params = new HttpParams().set('users', subscribers.join(','));

    return this.http.patch(url, {}, { params });
  }

  deleteCaseEvent(caseEvent) {
    this.caseDeleted.next(caseEvent);
  }

  isCaseDeleted() {
    return this.caseDeleted.asObservable();
  }

  isCaseDetectedDiscarded() {
    return this.caseDetectedDiscarded$.asObservable();
  }

  // New functions for new endpoints (Cases refactor)
  updateType(case_id: number, case_type: CaseType): Observable<boolean> {
    const url = `${environment.casesPath}${case_id}/type/${case_type.id}`;
    return this.http.patch<boolean>(url, {});
  }

  updateDescription(case_id: number, description: string): Observable<boolean> {
    const url = `${environment.casesPath}${case_id}/description`;
    const params = new HttpParams().set('description', description);
    return this.http.patch<boolean>(url, {}, { params });
  }

  updateTitle(case_id: number, title: string): Observable<boolean> {
    const url = `${environment.casesPath}${case_id}/title`;
    const params = new HttpParams().set('title', title);
    return this.http.patch<boolean>(url, {}, { params });
  }

  updateAreas(area: AreaEntity, caseId: number): Observable<Case> {
    const url = `${environment.casesPath}${caseId}/areas`;
    return this.http.put<Case>(url, area);
  }

  getOnsiteGuests(customer_id: number): Observable<OnsiteGuests[]> {
    const url = `${environment.casesPath}customers/${customer_id}/onsite/guests`;
    return this.http.get<OnsiteGuests[]>(url);
  }

  getTeams(customer_id: number): Observable<Team[]> {
    const url = `${environment.customerPath}teams/simple/customer/${customer_id}`;
    return this.http.get<Team[]>(url);
  }

  getAreas(customer_id: number, language_id: number): Observable<{ name: string; id: number }[]> {
    const url = `${environment.casesPath}customers/${customer_id}/areas`;
    const params = new HttpParams().set('language_id', language_id);
    return this.http.get<{ name: string; id: number }[]>(url, { params });
  }

  getUsers(customer_id: string, filter_superadmin?): Observable<User[]> {
    let params: HttpParams = new HttpParams().set('customer_id', customer_id);
    if (filter_superadmin) {
      params = params.append('filter_superadmin', filter_superadmin);
    }
    return this.http.get<User[]>(`${environment.customerUser}/`, { params: params }).pipe(map((response) => response));
  }

  seenByAssignedUser(case_id: number) {
    const url = `${environment.casesPath}${case_id}/viewing`;
    return this.http.patch(url, {});
  }

  setKanbanView(value: boolean, sentToGA: boolean = true) {
    if (sentToGA) this.utilService.ga('cases', value ? 'desk-switch-change-to-kanban-view' : 'desk-switch-change-to-list-view');
    localStorage.setItem('cases_kanban_view', JSON.stringify(value));
  }

  getKanbanView() {
    return JSON.parse(localStorage.getItem('cases_kanban_view'));
  }

  getTodayDate(): string {
    const today = new Date();
    return this.formatDate(today);
  }

  getDateMinusDays(days: number): string {
    const today = new Date();
    today.setDate(today.getDate() - days);
    return this.formatDate(today);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getDetectedCase(answerId: string): Observable<DetectedCase> {
    const url = `${environment.casesPath}detected/${answerId}`;
    return this.http.get<DetectedCase>(url);
  }

  getDetectedCaseByType(itemType: string, itemId: string): Observable<DetectedCase> {
    const url = `${environment.casesPath}detected/${itemType}/${itemId}`;
    return this.http.get<DetectedCase>(url);
  }

  getDetectedCaseByProduct(customerId): Observable<DetectedCasesByProduct[]> {
    const url = `${environment.casesPath}/customers/${customerId}/cases/detected`;
    const endDate = DateTime.now();
    const startDate = endDate.minus({ days: 30 });
    const params = new HttpParams()
      .set('start_date', startDate.toFormat('yyyy-MM-dd'))
      .set('end_date', endDate.toFormat('yyyy-MM-dd'));
    return this.http.get<DetectedCasesByProduct[]>(url, {params});
  }

  discardDetectedCase(answerId: string): Observable<any> {
    const url = `${environment.casesPath}detected/${answerId}/discard`;
    return this.http.patch(url, {}).pipe(tap(() => this.caseDetectedDiscarded$.next(true)));
  }

  discardDetectedCaseByType(itemType: string, itemId: string): Observable<any> {
    const url = `${environment.casesPath}detected/${itemType}/${itemId}/discard`;
    return this.http.patch(url, {}).pipe(tap(() => this.caseDetectedDiscarded$.next(true)));
  }

  getScheduleData(hotelId: string): Observable<ScheduleData> {
    const url = `${environment.customerPath}customers/${hotelId}/calendar`;
    return this.http.get<ScheduleData>(url);
  }

  sendScheduleNotification(hotelId: string, generationTypeId = 1) {
    const url = `${environment.casesPath}customers/${hotelId}/demo/alert`;
    const params = new HttpParams().set('generation_type_id', generationTypeId);
    return this.http.post(url, {}, { params });
  }
}
