<!-- Status chip responsive -->
<div class="tw-flex tw-flex-col lg:tw-hidden">
  <ng-container *ngTemplateOutlet="statusChip"></ng-container>

  <div class="tw-flex tw-mt-2 tw-mr-3 tw-justify-end lg:tw-hidden">
    <i class="far fa-times tw-cursor-pointer tw-text-gray-400 tw-text-3xl" (click)="close()"></i>
  </div>
</div>

<!-- Formulario para comentar responsive -->
<ng-container *ngTemplateOutlet="commentFormTemplate"></ng-container>

<article #detailCaseContainer class="tw-relative tw-flex tw-h-auto tw-min-h-full tw-max-h-full tw-flex-col tw-overflow-y-auto tw-px-3 lg:tw-flex-row lg:tw-px-0">
  <!-- Inicio responsive -->
  <div class="tw-px-3 tw-pt-2 tw-max-w-full lg:tw-hidden">
    <div *ngIf="!waiting || showCaseForm">
      <div class="tw-hidden lg:tw-flex">
        <ng-container *ngTemplateOutlet="statusChip"></ng-container>
      </div>
      <ng-container *ngIf="showCaseForm">
        <ng-container *ngTemplateOutlet="caseForm"></ng-container>
      </ng-container>
    </div>
  </div>
  <!-- Final responsive -->

  <!-- Formulario del caso -->
  <section class="tw-flex-col tw-min-w-[340px] tw-max-w-[340px] tw-px-7 tw-py-10 tw-hidden lg:tw-flex">
    <div class="tw-absolute tw-right-4 tw-top-4">
      <i class="far fa-times tw-cursor-pointer tw-text-gray-400 tw-text-3xl" (click)="close()"></i>
    </div>
    <ng-container *ngTemplateOutlet="statusChip"></ng-container>

    <section class="tw-mt-5">
      <ng-container *ngIf="!waiting; else loader">
        <ng-container *ngTemplateOutlet="caseForm"></ng-container>
      </ng-container>
    </section>
  </section>

  <!-- Detalle del caso -->
  <section class="tw-flex tw-flex-col tw-px-5 tw-py-2 tw-grow tw-max-w-full lg:tw-max-w-[71%] lg:tw-py-12">
    <ng-container *ngTemplateOutlet="caseDetail"></ng-container>
  </section>
</article>

<ng-template #caseForm>
  <mh-core-lib-case-detail-form
    [types]="types"
    [displayFnTypes]="displayFnTypes"
    [priorities]="priorities"
    [displayFnPriorities]="displayFnPriorities"
    [areas]="data.areas"
    [displayChipsFn]="displayChipsFn"
    [users]="users"
    [states]="states"
    [caseData]="caseData"
    [subscribers]="data.subscribers"
    [currentUser]="currentUser"
    [customerId]="customerId"
    (caseUpdated)="handleUpdatedCase($event)"
  ></mh-core-lib-case-detail-form>
</ng-template>

<ng-template #caseDetail>
  <section class="tw-flex tw-items-center tw-justify-start">
    <div *ngIf="showDiscardCase" class="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-10"></div>
    <ng-container *ngTemplateOutlet="discardCase"></ng-container>
    <ng-container *ngFor="let state of optionStates">
      <ng-container *ngIf="state.id !== 4; else discardedOption">
        <mh-core-lib-actions-wrapper
          slug="cases_cases_status_change"
          [skipValidation]="isAssigned"
        >
          <div class="pointer tw-w-24 md:tw-w-52" [ngClass]="{ '!tw-bg-sky-300':  caseData?.state?.id === state.id, 'tw-pointer-events-none': loading}" (click)="handleStateChange(state)">
            <div class="pointer-border tw-w-[calc(6rem-2px)] md:tw-w-[calc(13rem-2px)] tw-text-gray-300 hover:!tw-bg-sky-300 hover:!tw-text-white" [ngClass]="{ '!tw-bg-sky-300': caseData?.state?.id === state.id }">
              <span class="tw-uppercase tw-text-xs lg:tw-text-2sm" [ngClass]="{ '!tw-bg-sky-300 !tw-text-white': caseData?.state?.id === state.id }">{{ 'cases.table_view.states.' + state.id | translate }}</span>
            </div>
          </div>
        </mh-core-lib-actions-wrapper>
      </ng-container>
      <ng-template #discardedOption>
        <mh-core-lib-actions-wrapper
          slug="cases_cases_status_change"
          [skipValidation]="isAssigned"
        >
          <span class="tw-ml-6 tw-cursor-pointer" (click)="caseData?.state?.id !== 4 ? showDiscardCase = !showDiscardCase : null" [mhTooltip]="caseData?.state?.id ? ('cases.detail_modal.click_to_discard' | translate) : null" position="above">
            <i class="fal fa-archive tw-text-gray-400 tw-text-2xl" [ngClass]="{ '!tw-text-sky-300': caseData?.state?.id === state.id }"></i>
          </span>
        </mh-core-lib-actions-wrapper>
      </ng-template>
    </ng-container>
    <div class="tw-absolute tw-right-12" *ngIf="!isMobileOrTablet">
      <div
          class="tw-cursor-pointer tw-rounded-full tw-p-5 tw-w-8 tw-h-8 tw-border tw-border-main-blue tw-flex tw-items-center tw-justify-center hover:tw-bg-sky-100"
          (click)="handleShareCase()"
        >
          <i class="far fa-share-alt tw-text-main-blue tw-text-2xl"></i>
        </div>
    </div>
  </section>

  <section
    class="tw-flex tw-items-center tw-justify-between tw-mt-5"
    [ngClass]="{'!tw-mt-6': isExpired || caseData?.state?.id === stateTypesEnum.FINISHED || caseData?.state?.id === stateTypesEnum.DISCARDED}"
  >
    <div>
      <ng-container *ngIf="!editing">
        <p class="tw-text-2xl tw-font-medium tw-mb-2 lg:tw-mb-0 lg:tw-inline-block tw-cursor-pointer" (click)="handleEditing()">{{ title }}</p>
      </ng-container>
      <ng-container *ngIf="editing">
        <mh-core-lib-text-input
          [style.visibility]="!loading ? 'visible' : 'hidden'"
          class="tw-w-full tw-my-1"
          [formGroup]="descriptionForm"
          keyControl="title"
        ></mh-core-lib-text-input>
      </ng-container>
      <div class="tw-flex tw-flex-col-reverse md:tw-flex-row tw-gap-2">
        <div class="tw-text-xl tw-font-medium tw-mb-2">
          <ng-container *ngIf="isMobileOrTablet; else desktopViewedEyeTemplate">
            <ng-container *ngIf="!caseData?.viewed; else viewedCaseMobile">
              <p class="tw-flex tw-items-center tw-font-normal">
                <span><i class="fas fa-eye-slash tw-text-sky-500"></i></span>
                <span class="tw-text-xs tw-ml-3">{{ 'cases.detail_modal.not_reviewed' | translate: { firstName: caseData?.assigned?.first_name, lastName: caseData?.assigned?.last_name } }}</span>
              </p>
            </ng-container>
            <ng-template #viewedCaseMobile>
              <p class="tw-flex tw-items-center tw-font-normal">
                <span><i class="fas fa-eye tw-text-sky-500"></i></span>
                <span class="tw-text-xs tw-ml-3">{{ caseData?.viewed_text }}</span>
              </p>
            </ng-template>
          </ng-container>

          <ng-template #desktopViewedEyeTemplate>
            <span *ngIf="!caseData?.viewed; else viewedCase" [mhTooltip]="'cases.detail_modal.not_reviewed' | translate: { firstName: caseData?.assigned?.first_name, lastName: caseData?.assigned?.last_name }" position="above"><i class="fas fa-eye-slash tw-text-sky-500"></i></span>
            <ng-template #viewedCase>
              <span [mhTooltip]="caseData?.viewed_text" position="above"><i class="fas fa-eye tw-text-sky-500"></i></span>
            </ng-template>
          </ng-template>
        </div>
        <div class="tw-flex tw-gap-1">
          <p class="tw-text-sm lg:tw-text-base">
            {{ caseData?.public_id }} - {{ 'cases.detail_modal.created_by' | translate }} <span class="tw-font-medium">{{ caseData?.author.first_name }} {{caseData?.author.last_name}} </span> <span class="tw-lowercase">{{ caseData?.time_management?.when_created_text }}.</span>
          </p>
          <section *ngIf="(!isExpired && (caseData?.state?.id === stateTypesEnum.PENDING || caseData?.state?.id === stateTypesEnum.PROCESS)) && caseData?.time_management?.little_text_remaining !== '-'">
            <div class="tw-flex tw-gap-1">
              <span class="tw-text-base">{{ 'cases.table_view.due_date' | translate }}</span>
              <span class="tw-text-base">{{ caseData?.time_management?.little_text_remaining }}</span>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>

  <section class="tw-mt-2 tw-grow lg:tw-mt-5">
    <ng-container *ngIf="!editing">
      <mh-user-mention class="tw-cursor-pointer" [users]="users" [text]="description" (click)="handleEditing()"></mh-user-mention>
    </ng-container>
    <ng-container *ngIf="editing">
      <ng-container *ngIf="!loading; else loader">
        <mh-core-lib-textarea-input
          [formGroup]="descriptionForm"
          [keyControl]="'description'"
          [withMentions]="true"
          [users]="users"
          [rows]="8"
          inputClass="tw-resize-none tw-h-auto"
          (mentionStateChanged)="handleMentionState($event)"
        ></mh-core-lib-textarea-input>
        <section class="tw-flex tw-gap-3 tw-justify-end">
          <mh-core-lib-button
            [isPrimary]="true"
            [text]="'commons.accept' | translate"
            [customClass]="'!tw-shadow-none !tw-px-2'"
            [textClass]="'tw-font-[Rubik] tw-text-sm'"
            [width]="100"
            [height]="30"
            (clicked)="handleAcceptEditDescription()"
          ></mh-core-lib-button>

          <mh-core-lib-button
            [isSecondary]="true"
            [text]="'commons.cancel' | translate"
            [customClass]="'!tw-shadow-none !tw-px-2'"
            [textClass]="'tw-font-[Rubik] tw-text-sm'"
            [width]="100"
            [height]="30"
            (clicked)="handleCancelEditDescription()"
          ></mh-core-lib-button>
        </section>
      </ng-container>
    </ng-container>

    <div class="tw-flex tw-gap-3 tw-mt-3">
      <ng-container *ngFor="let file of downloadedFiles">
        <span *ngIf="loadingImage && selectedFile?.file_key === file?.initialFileData?.file_key; else imageContainer" class="tw-block tw-w-min"><mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper></span>
        <ng-template #imageContainer>
          <img *ngIf="acceptedImageTypes.includes(file.type); else fileIcon" class="tw-w-24 tw-h-16 tw-rounded-md tw-cursor-pointer" [src]="file.link" (click)="expandImage(file, file.initialFileData)">
          <ng-template #fileIcon>
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-32 tw-h-24 tw-rounded-md tw-cursor-pointer tw-border tw-border-gray-300" (click)="showOrDownloadFile(file)" [mhTooltip]="file.file_name" position="above">
              <span *ngIf="loadingImage && selectedFile?.file_key === file.file_key; else docName" class="tw-block tw-w-min"><mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper></span>

              <ng-template #docName>
                <i class="fal fa-file-invoice tw-text-2xl"></i>
                <span class="tw-truncate tw-w-4/5 tw-text-xs tw-mt-2">{{ file.file_name }}</span>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
    </div>

    <div class="tw-mt-5 tw-mb-3 lg:tw-mb-0" *ngIf="caseData?.product_item || caseData?.guest_params">
      <mh-core-lib-product-for-case
        [case]="caseData"
        [item]="caseData?.guest_params"
        [type]="caseData?.product_item?.type?.name"
        [language]="language"
        [product]="caseData?.product_item"
        [source]="'detail'"
        [customerId]="caseData?.customer?.id"
        [associatedGuest]="caseData?.guest_params"
        [isMobileOrTablet]="isMobileOrTablet"
        [customClassContainer]="'!tw-py-4'"
      ></mh-core-lib-product-for-case>
    </div>
  </section>

  <section class="tw-flex tw-flex-col tw-h-40 tw-grow-[2] tw-mt-2">
    <!-- Caja de comentarios -->
    <div *ngIf="!isMobileOrTablet" class="tw-flex tw-gap-4 tw-items-start">
      <div class="tw-flex-col tw-flex-grow-1 tw-relative tw-w-full tw-p-[2px] tw-border-2 tw-rounded-2xl">
        <div class="tw-flex-grow tw-flex tw-gap-2 tw-relative">
          <mh-core-lib-textarea-input
            class="tw-w-full"
            [customContainerClass]="'!tw-flex'"
            [placeholder]="'cases.detail_modal.write_a_comment' | translate"
            [formGroup]="commentForm"
            [keyControl]="'comment'"
            [withMentions]="true"
            [users]="users"
            [rows]="1"
            [autoRezise]="true"
            [inputClass]="'!tw-font-[Rubik] tw-border-gray-400 tw-resize-none !tw-border-0 tw-pr-9'"
            (mentionStateChanged)="handleMentionState($event)"
          ></mh-core-lib-textarea-input>
          <i class="fal fa-paperclip tw-absolute tw-top-[12.5px] tw-right-[17px] tw-cursor-pointer tw-text-gray-500" [mhTooltip]="'cases.detail_modal.attach_files' | translate" (click)="fileInput.click()"></i>
        </div>
        <!-- Uploader -->
        <div *ngIf="!isMobileOrTablet" [ngClass]="{'tw-p-3': filesUploaded?.length > 0  || uploadingFiles }">
          <mh-core-lib-file-uploader
            [customerId]="customerId"
            [style]="'chips'"
            [completedActions]="completedActions"
            (filesUploaded)="handleFilesUploaded($event)"
            (uploadingFilesProgress)="uploadingFiles = $event"
            (emitFileInput)="fileInput = $event"
          ></mh-core-lib-file-uploader>
        </div>
      </div>

      <mh-core-lib-button
        [mhTooltip]="'cases.detail_modal.send_comment' | translate"
        position="above"
        [isDisabled]="(commentForm.get('comment')?.value?.length < 1 && (!filesUploaded || filesUploaded.length === 0)) || uploadingFiles || sendingComment"
        text="{{ 'commons.send' | translate }}"
        [isPrimary]="true"
        [width]="90"
        [height]="40"
        (clicked)="submitComment()"
        customClass="!tw-rounded-md">
      </mh-core-lib-button>
    </div>
    <!-- Historial -->
    <ng-container *ngIf="!loadingActivities; else loader">
        <div class="tw-pb-36 lg:tw-pb-4">
          <ng-container *ngFor="let activity of newActivities">
            <div class="tw-flex tw-items-center tw-mt-4 lg:tw-gap-4">
              <div class="tw-flex-none">
                <img class="tw-w-8 tw-rounded-full" [src]="activity?.user_photo_url ?? defaultProfilePhoto" alt="Profile photo">
              </div>
              <p class="tw-flex tw-flex-col tw-flex-auto tw-ml-2 lg:tw-w-full lg:tw-ml-0">
                <ng-container *ngIf="activity?.request_method_name === 'createComment'; else actionComment">
                  <p class="tw-w-full tw-flex tw-items-baseline tw-gap-1">
                    <span class="tw-font-bold">{{ activity?.author_comment }}</span>
                    <span class="tw-text-xs tw-text-gray-400 tw-flex lg:tw-hidden">{{ activity?.time_temporality_rule }}</span>
                  </p>
                  <span class="tw-w-full" [innerHTML]="sanitizer.bypassSecurityTrustHtml(activity?.log_content?.text)"></span>
                </ng-container>
                <ng-template #actionComment>
                  <span class="tw-w-full" [innerHTML]="activity?.activity_text"></span>
                </ng-template>
              </p>
              <p class="tw-text-gray-400 tw-flex-none tw-hidden lg:tw-flex">
                {{ activity?.time_temporality_rule }}
              </p>
            </div>

            <div *ngIf="activity?.log_content?.attachments && activity?.log_content?.attachments.length > 0" class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-ml-14 tw-mt-2">
              <span class="tw-text-xs">{{ 'commons.attachments' | translate }}</span>
              <div *ngFor="let file of activity?.log_content?.attachments" class="tw-rounded-xl tw-py-1 tw-px-5 tw-bg-notification-bg-back-button tw-text-black tw-text-xs tw-cursor-pointer" (click)="showOrDownloadFile(file, 'comment')">
                <span *ngIf="loadingImage && selectedFile?.file_key === file.file_key; else fileName" class="tw-block tw-w-min"><mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper></span>
                <ng-template #fileName>
                  {{ file?.file_name }}
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
  </section>
</ng-template>

<ng-template #statusChip>
  <div class="tw-flex tw-justify-between tw-text-main-text tw-items-center tw-w-full lg:tw-w-fit">
    <ng-container *ngIf="caseData?.time_management?.status_chip_color">
      <span
        [hidden]="caseData?.time_management?.subcategory_state_generic === 'stuck' && isHistoryPage"
        class="tw-py-2 tw-px-4 tw-rounded-none tw-font-semibold tw-text-xs tw-w-full tw-text-center lg:tw-text-base lg:tw-rounded-full"
        [ngClass]="{
          'tw-bg-red-500 tw-text-white': caseData?.time_management?.status_chip_color === 'red',
          'tw-bg-yellow-400 tw-text-black': caseData?.time_management?.status_chip_color === 'yellow'
        }"
        [mhTooltip]="caseData?.time_management?.time_type?.total ? ('cases.table_view.due_on' | translate: { date: dueDateText, time: dueTimeText }) : ('cases.table_view.without_due_date' | translate)"
        position="above"
      >
        <ng-container *ngIf="!caseData?.time_management?.time_type; else hasChipText">{{ 'cases.detail_modal.stagnant' | translate }}</ng-container>
        <ng-template #hasChipText>{{ [stateTypesEnum.FINISHED, stateTypesEnum.DISCARDED].includes(caseData.state.id) ? caseData?.time_management?.status_chip.split(' ')[0] : caseData?.time_management?.status_chip }}</ng-template>
      </span>
    </ng-container>
  </div>
</ng-template>


<ng-template #loader>
  <div class="tw-text-3xl tw-mt-5">
    <mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper>
  </div>
</ng-template>

<ng-template #commentFormTemplate>
  <div
    @fadeSlideInOut
    id="commentFormTemplate"
    *ngIf="showCommentForm"
    class="tw-w-full tw-p-6 tw-h-full tw-bg-white tw-absolute tw-z-10 tw-top-[4%] tw-left-0 tw-shadown-none"
  >
    <div class="tw-flex tw-gap-4 tw-items-center">
      <mh-core-lib-textarea-input
        class="tw-w-full"
        [placeholder]="'cases.detail_modal.write_a_comment' | translate"
        [formGroup]="commentForm"
        [keyControl]="'comment'"
        [withMentions]="true"
        [users]="users"
        [rows]="7"
        [inputClass]="'!tw-font-[Rubik] tw-border-gray-400 tw-resize-none'"
        (mentionStateChanged)="handleMentionState($event)"
      ></mh-core-lib-textarea-input>
    </div>

    <div class="tw-my-3">
      <mh-core-lib-file-uploader
        [customerId]="customerId"
        [style]="'chips'"
        [completedActions]="completedActions"
        (filesUploaded)="handleFilesUploaded($event)"
        (uploadingFilesProgress)="uploadingFiles = $event"
        (emitFileInput)="fileInput = $event"
      ></mh-core-lib-file-uploader>
    </div>

    <div class="tw-flex tw-justify-around tw-items-center">
      <mh-core-lib-button
        [isDisabled]="uploadingFiles"
        [isSecondary]="true"
        [icon]="'far fa-arrow-left'"
        [width]="45"
        [height]="45"
        (clicked)="showCommentForm = !showCommentForm"
        customClass="!tw-rounded-full">
      </mh-core-lib-button>
      <mh-core-lib-button
        [isDisabled]="!commentForm.get('comment').value || uploadingFiles"
        [text]="'Adjuntar archivo'"
        [isSecondary]="true"
        [width]="150"
        [height]="45"
        (clicked)="fileInput.click()"
        customClass="!tw-rounded-full">
      </mh-core-lib-button>
      <mh-core-lib-button
        [isDisabled]="uploadingFiles"
        [isPrimary]="true"
        [text]="'Enviar'"
        [width]="90"
        [height]="45"
        (clicked)="submitComment()"
        customClass="!tw-rounded-full">
      </mh-core-lib-button>
    </div>
  </div>
</ng-template>


<!-- Footer -->
<div class="tw-flex tw-fixed tw-bottom-0 tw-w-full tw-bg-white tw-justify-between tw-py-2 tw-px-4 tw-shadow-[0px_0px_9px_#d6d5d5] lg:tw-hidden">
  <mh-core-lib-button
    [isSecondary]="true"
    [text]="'Comentar'"
    [customClass]="'!tw-shadow-none !tw-px-2'"
    [textClass]="'tw-font-[Rubik] tw-text-sm'"
    [width]="100"
    [height]="45"
    (clicked)="showCommentForm = !showCommentForm"
  ></mh-core-lib-button>

  <mh-core-lib-button
    [isSecondary]="true"
    [text]="'Compartir'"
    [customClass]="'!tw-shadow-none !tw-px-2'"
    [textClass]="'tw-font-[Rubik] tw-text-sm'"
    [width]="100"
    [height]="45"
    (clicked)="handleShareCase()"
  ></mh-core-lib-button>

  <mh-core-lib-button
    [isPrimary]="true"
    [text]="'Gestionar'"
    [customClass]="'!tw-shadow-none !tw-px-2'"
    [textClass]="'tw-font-[Rubik] tw-text-sm'"
    [width]="100"
    [height]="45"
    (clicked)="showCaseForm = !showCaseForm"
  ></mh-core-lib-button>
</div>

<ng-template #discardCase>
  <div
    @fadeSlideInOut
    *ngIf="showDiscardCase"
    class="tw-w-full tw-h-full xl:tw-h-[79%] tw-bg-white tw-absolute tw-z-10 tw-top-0 xl:tw-top-[21%] tw-left-0 tw-shadown-none xl:tw-shadow-[0px_0px_9px_#d6d5d5]"
  >
    <mh-case-dismiss-modal [reasons]="base.reasons_discarded" (handleClose)="showDiscardCase = false;" (handleDiscardCase)="handleDiscardCase($event)"></mh-case-dismiss-modal>
  </div>
</ng-template>
